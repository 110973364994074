<template>
  <el-dialog
      title="服务人员详情"
      :close-on-click-modal="false"
      :visible.sync="isOpenWatch"
      width="1100px"
      @closed="closeModal">
    <div style="width: 100%;">
      <ch-form
          :render-option="option"
          :model="modalForm"
          ref="form"
          :splice="[12,9]"
          :props="{ labelWidth: '120px', paddingRight: '10px' }"
      >
        <template v-slot:city>
          <el-cascader
              ref="city"
              v-model="modalForm.selectedOptions"
              :props="{ multiple: true,readonly: true }"
              collapse-tags
              size="small"
              >
          </el-cascader>
        </template>
        <template v-slot:classify>
          <el-cascader
              :options="treeList"
              v-model="modalForm.classifyIds"
              :props="{ multiple: true,value: 'id', label: 'name', children: 'children',emitPath:false }"
              collapse-tags :show-all-levels="false"
              size="small"
          >
          </el-cascader>
        </template>
        <template v-slot:province>
          <el-cascader
              ref="province"
              :options="provinceOption"
              v-model="modalForm.province"
              :props="{ multiple: false,emitPath:false,clearable:true }"
              collapse-tags :show-all-levels="false"
              size="small"
          >
          </el-cascader>
        </template>
      </ch-form>
    </div>
  </el-dialog>
</template>

<script>
// import { regionData,pcTextArr } from 'element-china-area-data'

import {pcTextArr} from "element-china-area-data";

export default {
  data() {
    return {
      isOpenWatch: false,
      modalForm: {},
      educationEnumArray: [
        { label: "小学", value: 1 },
        { label: "初中", value: 2 },
        { label: "高中", value: 3 },
        { label: "大专", value: 4 },
        { label: "本科", value: 5 },
        { label: "硕士", value: 6 },
        { label: "博士", value: 7 },
        { label: "其他", value: 8 }
      ],
      nationEnumArray: [
        { label: "未知", value: 0 },
        { label: "汉族", value: 1 },
        { label: "蒙古族", value: 2 },
        { label: "回族", value: 3 },
        { label: "藏族", value: 4 },
        { label: "维吾尔族", value: 5 },
        { label: "苗族", value: 6 },
        { label: "彝族", value: 7 },
        { label: "壮族", value: 8 },
        { label: "布依族", value: 9 },
        { label: "朝鲜族", value: 10 },
        { label: "满族", value: 11 },
        { label: "侗族", value: 12 },
        { label: "瑶族", value: 13 },
        { label: "白族", value: 14 },
        { label: "土家族", value: 15 },
        { label: "哈尼族", value: 16 },
        { label: "哈萨克族", value: 17 },
        { label: "傣族", value: 18 },
        { label: "黎族", value: 19 },
        { label: "傈僳族", value: 20 },
        { label: "佤族", value: 21 },
        { label: "畲族", value: 22 },
        { label: "高山族", value: 23 },
        { label: "拉祜族", value: 24 },
        { label: "水族", value: 25 },
        { label: "东乡族", value: 26 },
        { label: "纳西族", value: 27 },
        { label: "景颇族", value: 28 },
        { label: "柯尔克孜族", value: 29 },
        { label: "土族", value: 30 },
        { label: "达斡尔族", value: 31 },
        { label: "仫佬族", value: 32 },
        { label: "羌族", value: 33 },
        { label: "布朗族", value: 34 },
        { label: "撒拉族", value: 35 },
        { label: "毛南族", value: 36 },
        { label: "仡佬族", value: 37 },
        { label: "锡伯族", value: 38 },
        { label: "阿昌族", value: 39 },
        { label: "普米族", value: 40 },
        { label: "塔吉克族", value: 41 },
        { label: "怒族", value: 42 },
        { label: "乌孜别克族", value: 43 },
        { label: "俄罗斯族", value: 44 },
        { label: "鄂温克族", value: 45 },
        { label: "德昴族", value: 46 },
        { label: "保安族", value: 47 },
        { label: "裕固族", value: 48 },
        { label: "京族", value: 49 },
        { label: "塔塔尔族", value: 50 },
        { label: "独龙族", value: 51 },
        { label: "鄂伦春族", value: 52 },
        { label: "赫哲族", value: 53 },
        { label: "门巴族", value: 54 },
        { label: "珞巴族", value: 55 },
        { label: "基诺族", value: 56 }
      ],
      treeList:[], // 服务类型 树
    }
  },
  computed: {
    option() {
      return [
        {type: 'image',  label: '上传照片：', src: this.modalForm.picture,},
        {type: 'text', label: '人员姓名：', prop: 'name' },
        {type: 'text', label: '手机号：', prop: 'phone' },
        {type: 'text', label: '年龄：', prop: 'age'},
        {type: 'text', label: '性别：', prop:'_gender'},
        {type: 'text', label: '学历：', prop: 'educationLevel' },
        {type: 'text', label: '民族：', prop: 'ethnicity' },
        {type: 'text', label: '身高：', prop: 'height',sign:'cm' },
        {type: 'slot',  label: '服务城市：', slotName: 'city' ,prop: 'selectedOptions',},
        {type: 'text', label: '街道地址：', prop: 'address'},
        {type: 'slot', label: '服务类型：', prop: 'classifyIds', slotName:'classify' },
        {type: 'text', label: '工作年限：', prop: 'workExperience',sign:'年' },
        {type: 'slot',  label: '籍贯城市：', slotName: 'province' ,prop: 'province',},
        {type: 'slot', label: '', slotName:'tags', prop: 'tags', labelWidth:'55px' },

      ]
    },
    provinceOption: function (){
      return  pcTextArr.map(item=>{
        if(item.label.includes('市')){
          item.children = null
        }
        return item
      })
    },
  },
  mounted() {
    this.getTreeList()
  },
  methods: {
    openModal(id) {
      this.isOpenWatch = true
      this.getDetail(id)
    },
    getDetail(id) {
      this.$curl.get(`/hm/serviceStaff/detail/${id}`).then(res => {
        this.modalForm = res.data
        this.modalForm.picture = this.modalForm.picture.split(',')
        this.modalForm._gender = this.modalForm.gender === 1 ? '男' : this.modalForm.gender ===2 ?'女': '未知'
        this.modalForm.educationLevel = this.educationEnumArray.find(item => item.value === this.modalForm.educationLevel)?.label
        this.modalForm.ethnicity = this.nationEnumArray.find(item => item.value === this.modalForm.ethnicity)?.label
        if(res.data.areaIdsJson){
          this.modalForm.selectedOptions = JSON.parse(res.data.areaIdsJson)
        }

      })
    },
    // 服务类型 树
    async getTreeList(){
      this.$curl.get('/hm/classify/tree').then(res=>{
        this.treeList = res.data
      })
    },
    closeModal() {
      this.isOpenWatch = false
    }
  }
}
</script>



<style scoped lang="scss">

</style>